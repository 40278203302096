import type { AbilityBuilder } from "@casl/ability";
import { VALUES_LIST_TABS } from "~/constants/list-values";
import { APPLICATION_STEP, ORGANISATION_STEP } from "~/constants/steps/steps";
import { ROLES } from "~/constants/users";
import type { FlatAppAbility } from "~/types/permissions";
export const getAdminPermissions = ({
  can
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
}) => {
  can(["create", "read", "update", "delete"], "Faq");
  can("seeBo", "dossiers");
  can("read", "dossiers", ["id", "application_form_answers"]);
  can("read", "dossiers", "validationsData.decision", {
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ASSIGNMENT, APPLICATION_STEP.VERIFICATION, APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.DECISION]
    }
  });
  can("read", ["values_lists", "pdf_templates", "Repository", "dispositifs", "departments", "sub_departments", "services"]);
  can("create", ["pdf_templates", "departments", "sub_departments", "services"]);
  can("update", ["values_lists", "pdf_templates", "departments", "sub_departments", "services"]);
  can(["create", "delete"], "values_lists", {
    for: VALUES_LIST_TABS.DISPOSITIF
  });
  can("read", "dossiers", "subvention_projects", {
    step: {
      $in: [APPLICATION_STEP.REDACTION, APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "liquidation", {
    step: {
      $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "applicant.isActive", {
    "applicant.is_active": {
      $eq: false
    }
  });
  can("read", "dossiers", "organisation.isActive");

  // Reference dispositifs
  can("update", "reference_dispositifs");

  //published
  can("delete", "dispositifs", {
    status: "published"
  });

  // PDF

  can(["update", "read"], "pdf_configs");
  can(["read", "update"], "pdf_documents");
  can("update", "contact_info");

  //users
  can("read", "dossiers", "users");
  can("read", "organisations", "list");
  can("read", "users");
  can("update", "users", ["id", "all"]);
  can("update", "users", "is_active", {
    roles: {
      $elemMatch: {
        type: {
          $ne: ROLES.ADMIN
        }
      }
    }
  });
  can("create", "users", "beneficiary_authorisations.beneficiaries");
  can("update", "users", "roles");

  // Organisations
  can("read", "organisations", "id");
  can("update", "organisations", "id", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
  });
  can("update", "organisations", "is_active", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
  });

  // Organisation memberships
  can("create", "organisations", "memberships", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
  });
  can("read", "organisations", "memberships");
  can("update", "organisations", "memberships.role", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
  });
  can("delete", "organisations", "memberships", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
    // memberships: {
    //   $elemMatch: {
    //     role: { $ne: "admin" }, // Check that there is at least one non-admin member
    //   },
    // },
  }); // Should improve this permission to handle the case where the user is the last admin of the organisation

  can("read", "organisations", "actions");

  // Organisation memberships authorisation files
  can("read", "organisations", "memberships.authorisation_files", {
    step: {
      $ne: ORGANISATION_STEP.APPROVED
    }
  });

  // Organisation validations
  can("read", "organisations", "validations", {
    step: ORGANISATION_STEP.MODIFICATION
  });
  can("create", "organisations", "validations", {
    step: {
      $nin: [ORGANISATION_STEP.MODIFICATION, ORGANISATION_STEP.APPROVED]
    }
  });

  // Organisation beneficiaries
  can("create", "organisations", "beneficiary_authorisations.beneficiaries", {
    step: {
      $in: [ORGANISATION_STEP.APPROVED]
    }
  });
  can("delete", "organisations", "beneficiary_authorisations.beneficiaries", {
    step: {
      $in: [ORGANISATION_STEP.APPROVED]
    }
  });
};